.List_header_com{
    font-size: 1.5em;
    height: 75px;
    line-height: normal;
    padding: 8px 0;
    font-weight: bold;
    letter-spacing: 1px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.List_detail{

    padding: 15px 0;
    display: block;/* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 6.5em;
    line-height: 1.8em;
}

.List_more{
    margin-top: 10px;
    font-size: 1em;
    width: auto;
    padding: 8px;
    border: rgb(153, 152, 152) solid;
    border-radius: 50px;
}

.List_link {
    text-decoration: none;
    color: #000;
    letter-spacing: 0.2px;
}

.submit-btn {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    padding: 8px 16px;
    border: none;
    width:200px;
    color: #fff;
    cursor: pointer;
}