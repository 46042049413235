
h1{
    font-size: 30px;
    text-transform: uppercase;
}
.summary_header{

    padding: 20px 0px;
}
table{
    width:100%;
    table-layout: fixed;
}
th{
    padding: 20px 15px;
    font-weight: 500;
    font-size: 1.1em;
    text-align: center !important;
    white-space: nowrap;
}
td{
    padding: 15px;
    vertical-align:middle;
    font-weight: 400;
    font-size: 0.9em;
    text-align: center;
    word-break: break-all;
    border-bottom: solid 1px rgba(22, 1, 1, 0.1);
}

section{
    margin: 50px;
    padding: 35px;
}

.nowrap {
    white-space:nowrap;
}
  