
.get-in-touch {
    max-width: 800px;
    margin: 50px auto;
    position: relative;
    padding: 50px;
}
.get-in-touch .title {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 3.2em;
    line-height: 48px;
    padding-bottom: 48px;
}

.contact-form .form-field {
    position: relative;
    margin: 32px 0;
}
.contact-form .input-text {
    display: block;
    width: 100%;
    height: 36px;
    border-width: 0 0 1px 0;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
}
.contact-form .input-text:focus {
    outline: none;
}

.contact-form .submit-btn {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    padding: 8px 16px;
    border: none;
    width:200px;
    color: #fff;
    cursor: pointer;
}
.ageclass {
    width: 100%;
    height: 36px;
    margin-top: 10px;
    display: inline-block;
    border-style: solid;
    border-width: 0 0 1px 0;
    font-size: 18px;
    color: #000;
}

.radio_input input {
    display: none;
}

.radio_input  label {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #7a7a7a;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember",sans-serif;
    letter-spacing: 1px;
    font-size: 1em;
    font-weight: 500;
    line-height: 28px;
    padding: 15px 0px;
    margin: 5px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 100%;
    transition-duration: 0.2s;
}

input:checked + label {
    background-color: rgb(117, 192, 114);
    color: white;
}
