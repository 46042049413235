.card-wrapper{
    max-width: 1100px;
    margin: 0 auto;
}
img{
    width: 100%;
    display: block;
}

.img-item:hover{
    opacity: 0.8;
}
.product-content{
    padding: 1rem 1rem;
}
.product-title{
    font-size: 2.5rem;
    text-transform: capitalize;
    font-weight: 600;
    position: relative;
    color: #12263a;
    margin: 1rem 0;
}

.new-price span{
    color: #256eff;
}
.product-detail span{
    color: rgb(92, 91, 91);
    font-size: 1.5rem;
}
.btn_wrapper{
    display: flex;
    justify-content: space-between;
}
.btn_wrapper button{
    display: block
}

button{
    border:none; 
    outline:none;
}


@media screen and (min-width: 992px){
    .card_detail{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }
    .card-wrapper{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-imgs{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .product-content{
        padding-top: 0;
    }
    
}