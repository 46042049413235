@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,100;1,300;1,400&family=Sarabun:wght@300;400;500;600;700&display=swap');

*{
    font-family: 'Lato', 'Sarabun';
    font-weight: 400;
}


.wrapper .left,
.wrapper .right {
    width: 100%;
    height: 50vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: end;
    justify-content: center;
}

li{
    padding: 5px 20px;
    display: inline-block;
    margin:0px auto;
    pointer-events: all;
    cursor: pointer;
}

li :hover{
    color: #85c3b4;
}


._navbar{
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 80px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

._navMenu{
    background-color: rgb(153, 152, 152);
    border-radius: 25px;
    padding: 5px;
    color: #f7f7f7;
}

.wrapper .image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease all;
}


.leftman {
    background-image: url("../asssets/Man.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    transform-origin:left;
}

.rightwoman {
    background-image: url("../asssets/Women.jpg");
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform-origin:right;
}



.wrapper .caption {
    position: relative;
    color: #fff;
    text-align: center;
    height: 0;
    transition: 0.8s;
    opacity: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 50%;
    height: 0;
}


.wrapper .caption h1 {
    text-transform: uppercase;
    
    color: #fff;
    letter-spacing: 0.25em;
    margin-bottom:1em;
}


.wrapper .caption .button {
    background: #000;
    color: #fff;
    padding: 1em 2em;
    text-decoration: none;
    font-weight: 600;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    transition: 0.25s ease all;
}

Link {
    text-decoration: none;
}


.wrapper .caption .button:hover {
    background: #ffd346;
    color: #000;
}


.wrapper .side::before {    
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
}


.wrapper .left:hover .image,
.wrapper .right:hover .image {
    transform: scale(1.3);
    transition: transform 0.8s;
    filter: blur(6px);
}

.selectAlert {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5em;
    width: 13px;
    height: 13px;
    color: #fff ;
    top: -3px ;
    left : 30px;
    border-radius: 100px;
    background-color: rgb(190, 66, 66);
}

@media screen and (min-width:1200px) {

    .wrapper {
        display: flex;
    }

    .wrapper .left,
    .wrapper .right {
        width: 50%;
        height: 100vh;
    }

    .bttn{
        cursor: pointer;
        padding: 30px;
        display: block;
        font-size: 1.5rem;
        font-weight: 700;
        transition: transform 0.5s;
    }

    .bttn:hover{
        color: #85c3b4;
        transform: scale(1.3);
        transition: 0.5s ease-out;
    }
    
    .wrapper .left:hover .caption,
    .wrapper .right:hover .caption {
        opacity: 1.0;
        height: 68%;
    }

}

@media screen and (max-width:1200px) {

    .bttn{
        cursor: pointer;
        padding: 20px;
        display: inline-block;
    }

    .bttn:hover{
        color: #85c3b4;
    }

    li{
        padding: 2px 5px;
        display: inline-block;
        margin:0px auto;
    }
    
    li :hover{
        color: #85c3b4;        
    }
    
    
    ._navbar{
        color: #fff;
        /* background: rgba(214, 222, 230, .9); */   
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btnwarper{
        display: flex;
        align-items: center;
    }

    ._navMenu{
        background-color: rgb(0, 124, 131);
        border-radius: 25px;
        padding: 5px;
        font-size: 0.8rem;
        color: #f7f7f7;
    }

    ._navMenuIcon{
        color: rgb(0, 124, 131);
        padding: 0 15px;
        display: flex;
        align-items: center;
        font-size: 1.5em;
    }

    .wrapper .left:hover .caption,
    .wrapper .right:hover .caption {
        opacity: 1.0;
        height: 55%;
    }
}
    



